@import "../vars";

.skeletonItem {
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #1e1e1e;
  border-radius: 8px;
}

.skeletonAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #333;
  margin-right: 15px;
}

.skeletonContent {
  flex: 1;
}

.skeletonHeader {
  height: 20px;
  width: 60%;
  background-color: #333;
  margin-bottom: 10px;
}

.skeletonText {
  height: 15px;
  width: 100%;
  background-color: #333;
  margin-bottom: 8px;
}

.skeletonAnimation {
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(to right, #1e1e1e 4%, #333 25%, #1e1e1e 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.errorMessage {
  color: #ff6b6b;
  text-align: center;
  padding: 20px;
}

.chatOuterContainer {
  background-color: $darker-bg;
  color: white;
  height: calc(100vh - 100px); // Adjust based on your layout
  display: flex;
  flex-direction: column;
}

.chatScrollContainer {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  margin: 0 auto;
  width: 95%;

  // Customizing scrollbar
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #27272a;
  }

  &::-webkit-scrollbar-thumb {
    background: #4a4a4d;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #5a5a5d;
  }
}

.chatItem {
  display: flex;
  align-items: flex-start;
  padding: 10px 25px;
  border-bottom: 1px solid $pink-50;
  width: 100%;
  transition: .25s ease-in-out;

  &:hover {
    background-color: $pink-10;
    transform: scale(1.025);
  }
}

.chatItem:hover {
  cursor: pointer;
}

.avatar {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 15px;
}

.chatContent {
  flex: 1;
}

.chatHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  h3 {
    font-size: 16px;
    margin: 0;
  }
}

.date {
  font-size: 12px;
  color: #a1a1aa;
}

.moreButton {
  background: none;
  border: none;
  color: #a1a1aa;
  font-size: 18px;
  cursor: pointer;
}

.lastMessage {
  font-size: 14px;
  color: #a1a1aa;
  margin: 0 0 5px 0;
  line-height: 1.4;
}

.replies {
  font-size: 12px;
  color: #a1a1aa;
}

.endOfList {
  text-align: center;
  padding: 20px;
  background-color: #1a1b1e;
  color: #a1a1aa;

  p {
    margin-bottom: 10px;
  }
}

.exploreLink {
  color: $pink-2;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 600px) {
  .chatScrollContainer {
    padding: 10px;
  }

  .chatItem {
    padding: 15px 0;
  }

  .avatar {
    width: 30px;
    height: 30px;
  }

  .chatHeader {
    flex-wrap: wrap;

    h3 {
      font-size: 14px;
    }
  }

  .date {
    font-size: 10px;
  }

  .lastMessage {
    font-size: 12px;
  }
}