@import "../../assets/vars";

.crt {
  position: fixed;
  right: 20px;
  top: 16px;
  width: 100px;
  height: 175px;
  background-color: transparent;
  overflow: hidden;
  pointer-events: none;
}

.circle {
  position: absolute;
  width: 4.4px;
  height: 4.4px;
  border-radius: 50%;
  background-color: $darker-bg;
  opacity: 0;
  transition: opacity 0.22s ease-in-out;
}

.dashboard {
  display: flex;
  height: 100vh;

  main {
    min-height: 100vh;
    width: 100%;
    flex: 1;
    height: 100vh;
    max-height: calc(100vh); /* Adjust the height as needed */
    overflow-y: auto;
    background-color: transparent;
    .container {
      padding: 0 5px;
      width: 100%;
      max-width: 1100px;
      margin: 0 auto;
      max-height: calc(100vh - 125px); /* Adjust the height as needed */
      overflow-y: auto;
      overflow-x: hidden;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      padding: 0px 0;

      h1 {
        color: #ece9ed;
        font-weight: bolder;
        text-shadow: 0 0 25px $pink-2;
      }
    }
  }
}

.menuButton {
  display: none;
}

.footer {
  background-color: $black-bg;
  padding: 10px;
  border-top: 1px solid $blue-10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerContent {
  margin-bottom: 20px;

  h3 {
    color: $pink-2;
    margin-bottom: 10px;
  }

  p {
    color: #fff;
    font-size: 14px;
  }
}

.footerLinks {
  display: flex;
  justify-content: space-between;

  h4 {
    color: #fff;
    margin-bottom: 10px;
    border-bottom: 1px dashed $pink-80;
    max-width: 80px;
    color: $pink-80;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 10px;

    li {
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}

@media (max-width: 768px) {
  .footer, .footerLinks {
    flex-direction: column;
  }

  header {
    margin: 0px;
    width: 100%;
  }
}

[data-state="active"] {
  background-color: white;
}


.elementSpacing {
  margin-bottom: 10px;
}

.topRow {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}

.bottomRow {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  width: 100%;

  .container {
    flex: 1;
  }
}

.infoBox {
  background: $pink-2;
  border: 2px solid $pink-10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  margin-top: 40px;
  .infoBoxTitle {
    font-size: 12px;
    color: $pink;
    display: block;
    margin-bottom: 3px;
  }
  .infoBoxSubTitle {
    font-size: 10px;
    display: block;
  }
}

.selectedDrawerButton {
  position: relative;

  span {
    background-color: $pink;
    color: black;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    padding: 2px 4px;
    pointer-events: none;
  }
}

.selectedAssetDrawer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 400px;
  background: rgba(30,35,42,0.80);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-left: 1px solid $pink-10;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 200ms ease;
  z-index: 10;

  &.drawerOpened {
    transform: translateX(0);
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: rgba(195,221,255,0.08);
    margin: 20px;

    h3 {
      font-size: 20px;
      color: $pink;
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      background-color: $pink-5;
      border: 2px solid $pink-10;
      cursor: pointer;
      transition: background-color 100ms ease;

      &:hover {
        background-color: $pink-10;
      }
    }
  }

  .selectedAssets {
    flex: 1;
    overflow: auto;
    padding: 20px;

    .selectedAsset {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        border-bottom: 1px solid $pink-10;
      }

      .info {
        display: flex;
        align-items: center;
        gap: 10px;

        h4 {
          font-size: 16px;
          font-weight: bold;
          color: $pink;
        }

        .image {
          height: 40px;
          width: 40px;
          position: relative;
          overflow: hidden;

          img {
            object-fit: cover;
            object-position: center center;
          }
        }
      }
    }
  }

  .action {
    padding: 20px;
    background: rgba(195,221,255,0.08);
  }
}

.ticker-container {
  overflow: hidden;
  height: 55px; /* Adjust to fit the size of your text */
  padding: 5px;
  margin-left: 10px;
}

.tickerText {
  font-size: 32px;
  font-weight: bolder;
  width: 100%;
  transition: transform 2s ease-in-out;
  color: #fff

}

.tickerTextEnter {
  transform: translateY(100%);
}

.tickerTextExit {
  transform: translateY(0);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: left;
  align-self: flex-start;
  width: 100%;
  margin: 10px;
}

.dateTitle {
  font-size: 18px;
}

.postCard {
  background: #e5e7eb;
  justify-content: space-between !important;
  height: 50px;
  width: 95%;
  padding: 10px;
  border-radius: 15px;
  margin: 10px;
  font-size: 12px;
}


@media (max-width: 1000px) {
  .topRow {
    grid-template-columns: 1fr;
  }

  .bottomRow {
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  main .header {
    h1 {
      display: none;
    }
  }

  .menuButton {
    display: inline-flex !important;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    background-color: $pink-25;
    border: 2px solid $pink-50;
    padding: 8px;
  }
}