@import "../vars";

.column {
  display: flex;
  flex-direction: column;
  padding: 2px;
}

.banner {
  width: 95%;
  margin: 10px auto;
  background: $pink-2;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  animation: pulse 2s infinite;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.bannerContent {
  flex: 1;
  
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    line-height: 1.2;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    columns: 1;
    
    li {
      font-size: 14px;
      margin-bottom: 5px;
      white-space: nowrap;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    h2 {
      font-size: 18px;
    }
    
    p {
      font-size: 14px;
    }
    
    ul {
      margin-bottom: 15px;

      li {
        font-size: 12px;
      }
    }
  }
}

.createButton {
  background-color: #fff;
  color: $pink-2;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  margin-left: 15px;
  align-self: center;
  margin: 5px;
  transition: .25s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 6px 12px;
    margin-left: 0;
    align-self: flex-end;
  }
}

.closeButton {
  display: none; // Hide the close button as it's not needed
}

.blurred {
  filter: blur(25px);
  transition: filter 0.3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.content {
  text-align: center;
}

.ratingText {
  font-size: 14px;
  margin-bottom: 10px;
}

.showButton {
  background: $pink-2;
  border: 2px solid $pink-50;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  border: none;
  padding: 5px 22px;
  border-radius: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: .25s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.skeleton {
  background: $black-bg;
  overflow: hidden;
  height: 40px;
  margin: 10px 0;
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 20px;
}

.skeletonImage {
  width: 100%;
  height: 200px;
  background: linear-gradient(90deg, $black-bg 25%, $black-bg 50%, $black-bg 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.skeletonText {
  height: 20px;
  margin: 10px;
  background: linear-gradient(90deg, $black-bg 25%, $black-bg 50%, $black-bg 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: #fff;
  margin-bottom: 25px;
  position: relative;

  .discordBanner {
    width: 100%;
    background-color: #5865F2;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-size: 14px;
  }

  .content {
    width: 100%;
    padding: 20px;
    
    h2 {
      font-size: 38px;
      line-height: 1.2;
      color: #fff;
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 1.4;
      color: #fff;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .experienceBox {
    background-color: $pink-2;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      
      li {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }

  .getStartedButton {
    background-color: #fff;
    color: $pink-2;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    height: auto;
    
    .content {
      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

.content {
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
      box-shadow: 0 0 0 12.5px rgba(255, 255, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  
  .messageLink {
    background: $pink-2;
    border: 2px solid $pink-10;
    font-size: 16px;
    padding: 20px;
    color: #fff;
    text-decoration: none;
    transition: background-color 100ms ease, color 100ms ease;
    display: inline-flex;
    gap: 10px;
    margin-bottom: 30px;
    font-weight: bolder;
    animation: pulse 2s infinite;
    
    @media (max-width: 768px) {
      margin-top: 25px;
      margin-bottom: 12px;
    }
    
    &:hover {
      background: $pink-2;
      animation: none;
    }
  }

  h2 {
    font-size: 38px;
    line-height: 66px;
    color: #fff;
    text-align: center;
    text-shadow: 0 0 30px $pink-50;
    margin-bottom: 22px;
  }

  p {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    text-shadow: 0 0 30px $pink-50;
    max-width: 700px;
    margin: 0 auto 40px;
  }

  .callToAction {
    display: inline-flex;
    gap: 20px;
  }
}

.typingText {
  display: inline-block;
  min-width: 10px;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: currentColor;
  margin-left: 2px;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

// Common carousel styles
.carouselSection {
  margin-bottom: 40px;
  position: relative;

  .sectionTitle {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .embla {
    overflow: hidden;
  }

  .emblaContainer {
    display: flex;
  }

  .emblaSlide {
    flex: 0 0 33.33%;
    min-width: 0;
    padding: 0 10px;
  }

  .emblaPrev, .emblaNext {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    border-radius: 50%;
  }

  .emblaPrev {
    left: 10px;
  }

  .emblaNext {
    right: 10px;
  }
}

// Discover section styles
.discoverSection {
  @extend .carouselSection;

  .embla {
    overflow: visible; // This allows partial slides to be visible
    margin: 0 -20px; // Adjust based on your layout
  }

  .emblaContainer {
    padding: 0 20px; // This creates space for partial slides
  }

  .emblaSlide {
    flex: 0 0 calc(100% / 3.5); // Allows for partial slides on both ends
    max-width: calc(100% / 3.5);
  }

  .characterCard {
    position: relative;
    width: 100%;
    height: 550px;
    border-radius: 10px;
    overflow: hidden;
  }

  .characterImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .characterInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    //background: linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0));
    background: $black-bg;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .characterName {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
    background-color: $black-bg;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 5px;
  }

  .chatButton, .voiceButton {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .chatButton {
    background-color: #fff;
    color: #000;

    &:hover {
      background-color: #e0e0e0;
    }
  }

  .voiceButton {
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .voiceIcon {
      margin-right: 5px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

// Trending section styles
.trendingSection {
  @extend .carouselSection;

  .emblaSlide {
    height: auto;
    flex: 0 0 calc(100% / 5.2);
  }

  .trendingCard {
    background-color: $darker-bg;
    border-radius: 10px;
    overflow: hidden;

    border: 1px solid $pink-25;
    height: 100%;
    transition: .25s ease-in-out;
  }

  .trendingCard:hover {
    cursor: pointer;
    transform:scaleX(1.05)
  }

  .trendingImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .trendingInfo {
    padding: 15px;
  }

  .trendingHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .trendingName {
    color: #fff;
    font-size: 18px;
    margin: 0;
  }

  .trendingLikes {
    color: #fff;
    font-size: 14px;
  }

  .trendingDescription {
    color: #ccc;
    font-size: 14px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .trendingFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nsfwTag {
    background-color: #ff4757;
    color: #fff;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
  }

  .viewsCount {
    color: #ccc;
    font-size: 14px;
  }
}

.featuredCreators {
  margin-top: 40px;
  background: linear-gradient(90deg, $pink-25 0%, $pink-10 100%);
  padding: 20px;
  border-radius: 10px;

  h2 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .creatorsContainer {
    position: relative;
  }

  .creators {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
  }

  .creator {
    background-color: $darker-bg;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    flex: 0 0 auto;
    width: 200px;

    .creatorImage {
      width: 100%;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      overflow: hidden;
      border: 3px solid $pink-2;
      margin-bottom: 10px;
    
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .creatorName {
      color: #fff;
      font-size: 16px;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .crown {
      font-size: 14px;
      vertical-align: top;
    }

    .creatorButton {
      background-color: $pink-25;
      color: #fff;
      border: 2px solid $pink-50;
      padding: 8px 12px;
      cursor: pointer;
      font-size: 14px;
      border-radius: 5px;
      font-weight: bold;
      transition: background-color 0.25s ease;

      &:hover {
        background-color: $pink-50;
      }
    }

    @media (max-width: 768px) {
      .creatorsContainer {
        overflow: hidden;
      }
  
      .creators {
        display: flex;
        scroll-snap-type: x mandatory;
        overflow-x: hidden;
        width: 100%;
      }
  
      .creator {
        flex: 0 0 100%;
        width: 100%;
        scroll-snap-align: start;
      }
  
      .scrollButtons {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
  
      .scrollButton {
        background-color: $pink-25;
        color: #fff;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        
        &:hover {
          background-color: $pink-50;
        }
      }
    }
  }

  .scrollIndicator {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    color: #fff;
  }
}

.characterGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.characterCard {
  background-color: $darker-bg;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s;
  border: 1px solid $pink-25;

  &:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }
  
  .imageContainer {
    position: relative;
    
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
    
    .likes {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: $darker-bg;
      color: #fff;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 14px;
    }

    .nsfwTag {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #ff4757;
      color: #fff;
      padding: 3px 8px;
      border-radius: 10px;
      font-size: 12px;
    }

    .deluxeTag {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: gold;
      color: black;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 14px;
    }
  }
  
  h3 {
    padding: 10px 10px 5px;
    font-size: 18px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  p {
    padding: 0 10px 10px;
    font-size: 14px;
    color: #aaa;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .cardFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: $darker-bg;
    
    .messages {
      color: #aaa;
      font-size: 14px;
    }
  }
}

.exploreRow {
  display: flex;
  align-items: center;
  flex-direction: row;
}
@media (max-width: 768px) {
  .characterGrid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .sectionTitle {
    font-size: 16.5px !important;
  }

  .exploreHeader {
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-direction: column;
  }

}

@media (max-width: 480px) {
  .characterGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1000px) {
  .carouselSection {
    .emblaSlide {
      flex: 0 0 100%;
    }
  }

  .featuredCreators {
    .creators {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .sectionTitle {
    font-size: 12px;
  }
}

.exploreSection {
  background-color: transparent;
  color: #fff;
  padding: 20px;
  font-family: Arial, sans-serif;

  .exploreHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-size: 24px;
      margin-right: 20px;
    }



    .discoverNew {
      background-color: transparent;
      border: none;
      color: #ff69b4;
      cursor: pointer;
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .filterSection {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    .filterButton {
      background-color: $pink-25;
      border: none;
      border-radius: 20px;
      color: #fff;
      padding: 5px 15px;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s;

      &.active {
        background-color: #ff69b4;
      }
    }

    .showMoreButton {
      background-color: transparent;
      border-bottom: 1px solid #fff;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      font-weight: bolder;
    }
  }
}

@media (max-width: 768px) {
  .discoverSection {
    .emblaSlide {
      flex: 0 0 100%;
      max-width: 100%;
    }
    
  }

  .featuredCreators {
    .creators {
      scroll-snap-type: x mandatory;
    }

    .creator {
      scroll-snap-align: center;
      width: 100%;
    }

    .scrollButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }

    .scrollButton {
      background-color: $pink-25;
      color: #fff;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      
      &:hover {
        background-color: $pink-50;
      }
    }
  }
}