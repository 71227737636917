@import "../vars";

.premiumOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start; // Changed from center to flex-start
  padding-top: 50px; // Added padding to push content down from the very top
  z-index: 1000;
}

.premiumContent {
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
  max-width: 80%; // Added to ensure content doesn't stretch too wide
  
  p {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.subscribeButton {
  padding: 10px 20px;
  background-color: $pink-55;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $pink-50;
  }
}

.blurredOverlay {
  filter: blur(5px);
  pointer-events: none;
}

.blurred {
  filter: blur(10px);
  transition: filter 0.3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.content {
  text-align: center;
}

.ratingText {
  font-size: 14px;
  margin-bottom: 10px;
}

.showButton {
  background: $pink-2;
  border: 2px solid $pink-50;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  border: none;
  padding: 5px 22px;
  border-radius: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: .25s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.exploreSection {
  background-color: transparent;
  color: #fff;
  padding: 20px;
  font-family: Arial, sans-serif;
  position: relative;
  max-width: 100%;

  .exploreHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-size: 24px;
      margin-right: 20px;
    }



    .discoverNew {
      background-color: transparent;
      border: none;
      color: #ff69b4;
      cursor: pointer;
      font-size: 14px;
    }
  }

  .filterSection {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    .filterButton {
      background-color: $pink-25;
      border: none;
      border-radius: 20px;
      color: #fff;
      padding: 5px 15px;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s;

      &.active {
        background-color: #ff69b4;
      }
    }

    .showMoreButton {
      background-color: transparent;
      border-bottom: 1px solid #fff;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      font-weight: bolder;
    }
  }
}

[data-state="active"] {
  background-color: white;
}

.searchButton {
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-left: 10px;
  background-color: $darker-bg;
  transition: .25s ease-in-out;
}

.searchInput {
  border: 1px solid #fff;
}

.searchButton:hover {
  transform: scale(1.05);
}
.elementSpacing {
  margin-bottom: 10px;
}

.topRow {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}

.bottomRow {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  width: 100%;

  .container {
    flex: 1;
  }
}

.infoBox {
  background: $pink-2;
  border: 2px solid $pink-10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  margin-top: 40px;
  .infoBoxTitle {
    font-size: 12px;
    color: $pink;
    display: block;
    margin-bottom: 3px;
  }
  .infoBoxSubTitle {
    font-size: 10px;
    display: block;
  }
}

.selectedDrawerButton {
  position: relative;

  span {
    background-color: $pink;
    color: black;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    padding: 2px 4px;
    pointer-events: none;
  }
}

.selectedAssetDrawer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 400px;
  background: rgba(30,35,42,0.80);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-left: 1px solid $pink-10;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 200ms ease;
  z-index: 10;

  &.drawerOpened {
    transform: translateX(0);
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: rgba(195,221,255,0.08);

    h3 {
      font-size: 20px;
      color: $pink;
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      background-color: $pink-5;
      border: 2px solid $pink-10;
      cursor: pointer;
      transition: background-color 100ms ease;

      &:hover {
        background-color: $pink-10;
      }
    }
  }

  .selectedAssets {
    flex: 1;
    overflow: auto;
    padding: 20px;

    .selectedAsset {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        border-bottom: 1px solid $pink-10;
      }

      .info {
        display: flex;
        align-items: center;
        gap: 10px;

        h4 {
          font-size: 16px;
          font-weight: bold;
          color: $pink;
        }

        .image {
          height: 40px;
          width: 40px;
          position: relative;
          overflow: hidden;

          img {
            object-fit: cover;
            object-position: center center;
          }
        }
      }
    }
  }

  .action {
    padding: 20px;
    background: rgba(195,221,255,0.08);
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px;
}

.searchRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin: 10px;
}

.dateTitle {
  font-size: 18px;
}

.postCard {
  background: #e5e7eb;
  justify-content: space-between !important;
  height: 50px;
  width: 95%;
  padding: 10px;
  border-radius: 15px;
  margin: 10px;
  font-size: 12px;
}


@media (max-width: 1000px) {
  .searchRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin:10px;
    margin-bottom: 25px;
    max-width: 380px;
  }

  .topRow {
    grid-template-columns: 1fr;
  }

  .bottomRow {
    flex-direction: column;
  }
}

.characterGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.characterCard {
  background-color: $darker-bg;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s;
  border: 1px solid $pink-25;

  &:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }
  
  .imageContainer {
    position: relative;
    
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
    
    .likes {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: $darker-bg;
      color: #fff;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 14px;
    }

    .nsfwTag {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #ff4757;
      color: #fff;
      padding: 3px 8px;
      border-radius: 10px;
      font-size: 12px;
    }

    .deluxeTag {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: gold;
      color: black;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 14px;
    }
  }
  
  h3 {
    padding: 10px 10px 5px;
    font-size: 18px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  p {
    padding: 0 10px 10px;
    font-size: 14px;
    color: #aaa;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .cardFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    
    .messages {
      color: #aaa;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .characterGrid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .characterGrid {
    grid-template-columns: 1fr;
  }
}