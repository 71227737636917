@import "../vars";

.ratingSelector {
  display: inline-flex;
  flex-direction: row-reverse;
  background-color: $pink-50;
  border-radius: 8px;
  overflow: hidden;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.ratingOption {
  background: none;
  border: none;
  color: #333;
  padding: 5px;
  cursor: pointer;
  font-size: 24px;
  transition: color 0.3s ease;

  &:hover,
  &:hover ~ .ratingOption {
    color: $pink-2;
  }

  &.selected,
  &.selected ~ .ratingOption {
    color: $pink-2;
  }
}

.submitButton { 
  background-color: $pink-2;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bolder;
  margin: 10px
}

.review {
  margin-bottom: 20px;
  padding: 15px;
  background-color: $black-bg;
  border-radius: 8px;
}

.reviewHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.reviewerName {
  font-weight: bold;
}

.reviewDate {
  color: #777;
}

.reviewRating {
  margin-bottom: 10px;
}

.numericRating {
  margin-left: 5px;
  color: #777;
}

.reviewComment {
  line-height: 1.6;
}

.reviewerInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reviewerName {
  font-weight: bold;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 400px;
  border-radius: 8px;
  padding: 20px;
  text-align: center;

  h2 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #888888;
    margin-bottom: 20px;
  }
}

.exploreButton {
  background-color: $pink-2;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $pink-50;
  }
}

.characterProfile {
  color: #fff;
  padding: 20px;
  background-color: $black-bg;
}

.profileHeader {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.characterImage {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.characterInfo {
  flex: 1;

  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.stats {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;

  span {
    font-size: 14px;
  }
}

.characterMeta {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
  font-size: 14px;

  .username {
    font-weight: bold;
  }

  .username:hover {
    cursor: pointer;
  }

  .followButton {
    background-color: $pink-2;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: darken($pink-2, 10%);
    }
  
    &.following {
      background-color: #fff;
      color: $pink-2;
  
      &:hover {
        background-color: $black-bg;
      }
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;

  .tag {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
  }
}

.actionButtons {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;

  button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }

  .primaryButton {
    background-color: #5d5dff;
    color: white;
  }

  .secondaryButton {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
  }
}

.tabContainer {
  background-color: #1e1e1e;
  border-radius: 10px;
  overflow: hidden;
}

.tabs {
  display: flex;
  background-color: #2a2a2a;

  button {
    padding: 15px 20px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;

    &.activeTab {
      border-bottom: 2px solid #5d5dff;
    }
  }
}

.reviews {
  padding: 20px;
  background-color: #2a2a2a;

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.leaveReview {
  color: #5d5dff;
  text-decoration: none;
  font-size: 14px;
}

.review {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .reviewHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .reviewerName {
      font-weight: bold;
    }
  }

  p {
    font-size: 14px;
  }
}

.aiGenerator {
  padding: 20px;

  h2 {
    font-size: 18px;
    margin-bottom: 10px;

    .alpha {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 2px 5px;
      border-radius: 5px;
      font-size: 12px;
      vertical-align: middle;
    }
  }
}

@media (max-width: 768px) {
  .profileHeader {
    flex-direction: column;
  }

  .characterImage {
    width: 100%;
    height: auto;
  }
}

.skeletonWrapper {
  @extend .characterProfile;
  animation: pulse 1.5s infinite;
}

.skeletonHeader {
  @extend .profileHeader;
}

.skeletonImage {
  width: 200px;
  height: 200px;
  background-color: #2a2a2a;
  border-radius: 10px;
}

.skeletonInfo {
  flex: 1;
}

.skeletonName, .skeletonStats, .skeletonMeta, .skeletonDescription, .skeletonTags {
  background-color: #2a2a2a;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.skeletonName {
  width: 50%;
}

.skeletonStats {
  width: 70%;
}

.skeletonMeta {
  width: 60%;
}

.skeletonDescription {
  height: 60px;
}

.skeletonTags {
  width: 80%;
}

.skeletonButtons {
  @extend .actionButtons;
  height: 40px;
  background-color: #2a2a2a;
  border-radius: 5px;
}

.skeletonTabs {
  @extend .tabContainer;
  height: 200px;
  background-color: #2a2a2a;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}