@import "../vars";

[data-state="active"] {
  background-color: white;
}

.filterSection {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;

  .filterButton {
    background-color: $pink-25;
    border: none;
    border-radius: 20px;
    color: #fff;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;

    &.active {
      background-color: #ff69b4;
    }
  }

  .showMoreButton {
    background-color: transparent;
    border-bottom: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: bolder;
  }
}

.elementSpacing {
  margin-bottom: 10px;
}

.topRow {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}

.bottomRow {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  width: 100%;

  .container {
    flex: 1;
  }
}

.infoBox {
  background: $pink-2;
  border: 2px solid $pink-10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  margin-top: 40px;
  .infoBoxTitle {
    font-size: 12px;
    color: $pink;
    display: block;
    margin-bottom: 3px;
  }
  .infoBoxSubTitle {
    font-size: 10px;
    display: block;
  }
}

.selectedDrawerButton {
  position: relative;

  span {
    background-color: $pink;
    color: black;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    padding: 2px 4px;
    pointer-events: none;
  }
}

.selectedAssetDrawer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 400px;
  background: rgba(30,35,42,0.80);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-left: 1px solid $pink-10;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 200ms ease;
  z-index: 10;

  &.drawerOpened {
    transform: translateX(0);
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: rgba(195,221,255,0.08);

    h3 {
      font-size: 20px;
      color: $pink;
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      background-color: $pink-5;
      border: 2px solid $pink-10;
      cursor: pointer;
      transition: background-color 100ms ease;

      &:hover {
        background-color: $pink-10;
      }
    }
  }

  .selectedAssets {
    flex: 1;
    overflow: auto;
    padding: 20px;

    .selectedAsset {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        border-bottom: 1px solid $pink-10;
      }

      .info {
        display: flex;
        align-items: center;
        gap: 10px;

        h4 {
          font-size: 16px;
          font-weight: bold;
          color: $pink;
        }

        .image {
          height: 40px;
          width: 40px;
          position: relative;
          overflow: hidden;

          img {
            object-fit: cover;
            object-position: center center;
          }
        }
      }
    }
  }

  .action {
    padding: 20px;
    background: rgba(195,221,255,0.08);
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin: 10px;
}

.dateTitle {
  font-size: 18px;
}

.postCard {
  background: #e5e7eb;
  justify-content: space-between !important;
  height: 50px;
  width: 95%;
  padding: 10px;
  border-radius: 15px;
  margin: 10px;
  font-size: 12px;
}

.categories {
  .jumpToCategory {
    margin-bottom: 40px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;

    h3 {
      margin-bottom: 10px;
      color: #fff
    }

    .categoryButton {
      margin: 5px;
      padding: 10px 20px;
      background-color: $pink-5;
      border: none;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
    }
  }

  .category {
    margin-bottom: 40px;

    h2 {
      margin-bottom: 20px;
      font-size: 24px;
    }

    .subcategories {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center; // Center the cards

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center; // Center cards vertically on mobile
      }

      .subcategoryCard {
        background-color: $pink-25;
        border: 1px solid $pink-50;
        color: #fff;
        border-radius: 12px;
        padding: 20px;
        width: 250px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        transition: .25s ease-in-out;

        @media (max-width: 768px) {
          width: 100%; // Full width on mobile
          max-width: 250px; // Maintain maximum width
        }

        &:hover {
          transform: scale(1.05);
          background-color: $pink-50;
          cursor: pointer;
        }

        .cardHeader {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;
          width: 100%;
        }

        .emoji {
          font-size: 18px;
        }

        h3 {
          margin: 0;
          font-size: 18px;
        }

        .images {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-top: 30px;
          width: 100%;

          img {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            object-fit: cover;
          }

          .more {
            width: 30px;
            height: 30px;
            background: #fff;
            border: 5px dashed $pink-2;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #000;
            font-weight: bolder;
          }
        }
      }
    }
  }
}

.scrollableRow {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */
  
  /* Webkit-based browsers */
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@media (max-width: 1000px) {
  .topRow {
    grid-template-columns: 1fr;
  }

  .bottomRow {
    flex-direction: column;
  }
}