@import "../../assets/vars.scss";

.inputWrapper {
  textarea,
  input {
    background-color: $pink-25 !important;
    color: #fff;
    outline: none;
    border: 2px solid $pink-10 !important;;
    border-radius: 5px;
    height: 50px;
    padding: 0 15px;
    font-size: 16px !important;
    caret-color: #fff !important;
    transition: border 100ms ease, box-shadow 100ms ease, background-color 100ms ease;
    width: 100%;

    &::placeholder {
      color: #ffffffc6;
      font-weight: bold;
    }

    &:hover {
      border: 2px solid $pink-2;
    }

    &:focus {
      border: 2px solid #fff;
      box-shadow: 0 0 20px 1px $pink-50;
      background-color: $pink-10;
    }

    &[type="password"] {
      font-family: sans-serif; // This is done to to change how the password is displayed
    }
  }

  textarea {
    height: 100px
  }
  &.textSmall {
    input {
      font-size: 13px;
    }
  }

  &.inputHasError input {
    border: 2px solid $red;
    box-shadow: 0 0 20px 1px $red-25;
    background-color: $red-10;
    color: $red;
  }

  &.thinput {
    input {
      height: 40px
    }
  }

  .inputLabel {
    display: flex;
    color: $pink-2;
    font-size: 14px !important;
    margin-bottom: 10px;
    text-shadow: 0 0 15px $pink-25;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
  }

  .inputError {
    color: $red;
    margin-top: 10px;
    font-size: 12px;
    text-shadow: 0 0 15px $red-25;
  }

  .tooltipWrapper {
    position: relative;

    .tooltipIndicator {
      cursor: help;
      font-size: 16px;
      border: 2px solid $pink-10;
      background-color: $pink-5;
      height: 20px;
      width: 20px;
      display: inline-block;
      text-align: center;
      transition: border 100ms ease;

      &:hover {
        border: 2px solid $pink-2;
      }
    }

    .tooltipMessage {
      position: absolute;
      top: calc(-100% - 15px);
      white-space: nowrap;
      right: 0;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      background: $pink-10;
      border: 2px solid $pink-2;
      box-shadow: 0 0 20px 1px $pink-10;
      padding: 5px;
      transition: opacity 100ms ease, visibility 100ms ease;
      opacity: 0;
      visibility: hidden;
      text-shadow: 0 0 15px $pink-25;
    }

    .tooltipIndicator:hover + .tooltipMessage {
      opacity: 1;
      visibility: visible;
    }
  }
}
