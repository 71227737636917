@import "../vars";

.followersGrid {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: transparent; // Dark background to match the image
}

.followerCard {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #2a2a2a; // Slightly lighter than the background
  border-radius: 4px;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: #333333; // Subtle highlight on hover
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: $pink-2; 
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
  }

  img.avatar {
    object-fit: cover;
  }

  .username {
    color: #ffffff;
    font-size: 16px;
  }
}

.followButton {
  background-color: $pink-2;
  color: white;
  transition: .2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.unfollowButton {
  background-color: #ff3333;
  color: white;
  transition: .2s ease-in-out;
  
  &:hover {
    transform: scale(1.05);
  }
}

.profileContainer {
  background-color: $darker-bg;
  color: white;
  padding: 20px;
}

.profileHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profileImage {
  width: 150px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  border: 2px solid $pink-2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.placeholderImage {
  width: 100%;
  height: 100%;
  background-color: #8351ff;
}

.profileInfo {
  h1 {
    font-size: 24px;
    margin: 0;
  }
  p {
    font-size: 14px;
    color: #a1a1aa;
  }
}

.profileActions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.actionButton {
  //background-color: #27272a;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  transition: .25s ease-in-out;
  &:hover {
    //background-color: #3f3f46;
    transform: scale(1.05);
  }
}

.tabContainer {
  display: flex;
  border-bottom: 1px solid #27272a;
  margin-bottom: 20px;
}

.tabButton {
  background-color: transparent;
  color: #a1a1aa;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  &:hover {
    color: white;
  }
}

.activeTab {
  color: white;
  border-bottom: 2px solid $pink-2;
}

.tabContent {
  min-height: 200px;
}

.emptyState {
  text-align: center;
  color: #a1a1aa;
  p {
    margin: 5px 0;
  }
}

.createButton {
  background-color: $pink-2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: $pink-2;
    transform: scale(1.1);
    transition: .2s ease-in-out;
  }
}

.charactersGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.characterCard {
  background-color: #27272a;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  h3 {
    font-size: 18px;
    margin: 10px;
  }

  p {
    font-size: 14px;
    margin: 0 10px 10px;
    color: #a1a1aa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.characterStats {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #1f1f23;

  span {
    font-size: 14px;
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .profileHeader {
    flex-direction: column;
    text-align: center;
  }

  .profileImage {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .profileActions {
    flex-direction: column;
  }

  .charactersGrid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.skeletonProfileHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.skeletonProfileImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #3f3f46;
  margin-right: 20px;
}

.skeletonProfileInfo {
  flex: 1;
}

.skeletonText {
  height: 20px;
  background-color: #3f3f46;
  margin-bottom: 10px;
  width: 70%;
  &:last-child {
    width: 50%;
  }
}

.skeletonProfileActions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.skeletonButton {
  width: 120px;
  height: 40px;
  background-color: #3f3f46;
  border-radius: 5px;
}

.skeletonTabContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.skeletonTab {
  width: 80px;
  height: 30px;
  background-color: #3f3f46;
  border-radius: 5px;
}

.skeletonTabContent {
  min-height: 200px;
}

.skeletonCharactersGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.skeletonCharacterCard {
  background-color: #27272a;
  border-radius: 8px;
  overflow: hidden;
}

.skeletonCharacterImage {
  width: 100%;
  height: 200px;
  background-color: #3f3f46;
}

.skeletonCharacterInfo {
  padding: 10px;
}

// Responsive adjustments for skeleton
@media (max-width: 768px) {
  .skeletonProfileHeader {
    flex-direction: column;
    text-align: center;
  }

  .skeletonProfileImage {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .skeletonProfileActions {
    flex-direction: column;
  }

  .skeletonCharactersGrid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}