@import "../vars";

.topCreatorsContainer {
  padding: 20px;
  color: $off-white;

  @media (max-width: 768px) {
    padding: 10px;
  }
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.tab {
  background: none;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  transition: color 0.3s;

  &:hover {
    color: $pink-80;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 15px;
  }
}

.activeTab {
  color: $pink-80;
  border-bottom: 2px solid $pink-80;
}

.podium {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 60px;
  height: 300px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-bottom: 30px;
  }
}

.podiumItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  position: relative;

  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.place1 {
  order: 2;

  @media (max-width: 768px) {
    order: 1;
  }

  .pedestal {
    height: 180px;
    background-color: gold;

    @media (max-width: 768px) {
      height: 60px;
      width: 60px;
    }
  }

  .avatar {
    width: 100px;
    height: 100px;
    border: 2.5px solid gold;

    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
    }
  }
}

.place2, .place3 {
  .pedestal {
    height: 140px;
    background-color: silver;

    @media (max-width: 768px) {
      height: 50px;
      width: 50px;
    }
  }

  .avatar {
    width: 80px;
    height: 80px;
    border: 2.5px solid silver;

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
    }
  }
}

.place2 {
  order: 1;
  @media (max-width: 768px) {
    order: 2;
  }
}

.place3 {
  order: 3;
  .pedestal {
    background-color: #cd7f32;
  }
  .avatar {
    border-color: #cd7f32;
  }
}

.creatorInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    position: static;
    transform: none;
    flex-direction: row;
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.avatar {
  border-radius: 50%;
  margin-bottom: 5px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.crown {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;

  @media (max-width: 768px) {
    top: -15px;
    font-size: 18px;
  }
}

.creatorName {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.pedestal {
  width: 80px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    border-radius: 50%;
    padding-bottom: 0;
    align-items: center;
  }
}

.place {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $pink-55;
  color: $off-white;
  font-weight: bold;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
}

.tableContainer {
  overflow-x: auto;
}

.creatorsTable {
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid $pink-50;

    @media (max-width: 768px) {
      padding: 8px;
      font-size: 12px;
    }
  }

  th {
    color: #fff;
    font-weight: bold;
  }

  td {
    color: $off-white;
  }
}



.creatorInfoTable {
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 100%;  
  left: 50%;
  margin-bottom: 5px;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }

  @media (max-width: 768px) {
    position: static;
    transform: none;
    flex-direction: row;
    margin-bottom: 0;
    margin-right: 10px;
  }
}