@import "../vars";

.premiumOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start; // Changed from center to flex-start
  padding-top: 50px; // Added padding to push content down from the very top
  z-index: 1000;
}

.premiumContent {
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
  max-width: 80%; // Added to ensure content doesn't stretch too wide
  
  p {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.subscribeButton {
  padding: 10px 20px;
  background-color: $pink-55;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $pink-50;
  }
}

.blurredOverlay {
  filter: blur(5px);
  pointer-events: none;
}

.blurred {
  filter: blur(10px);
  transition: filter 0.3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.content {
  text-align: center;
}

.ratingText {
  font-size: 14px;
  margin-bottom: 10px;
}

.showButton {
  background: $pink-2;
  border: 2px solid $pink-50;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  border: none;
  padding: 5px 22px;
  border-radius: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: .25s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.skeletonCharacterGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.skeletonCharacterCard {
  background-color: #1e1e1e;
  border-radius: 10px;
  overflow: hidden;
  height: 400px;
  display: flex;
  flex-direction: column;

  .skeletonImageContainer {
    position: relative;
    height: 300px;
    background-color: #2a2a2a;
    animation: pulse 1.5s infinite;

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      width: 60px;
      height: 24px;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 15px;
      animation: pulse 1.5s infinite;
    }
  }

  .skeletonContent {
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .skeletonTitle {
      height: 18px;
      background-color: #2a2a2a;
      margin-bottom: 10px;
      width: 80%;
      animation: pulse 1.5s infinite;
    }

    .skeletonDescription {
      height: 32px; // Adjusted to match 2 lines of text
      background-color: #2a2a2a;
      animation: pulse 1.5s infinite;
      margin-bottom: auto;
    }

    .skeletonFooter {
      height: 20px;
      background-color: #2a2a2a;
      margin-top: 10px;
      width: 50%;
      align-self: flex-end;
      animation: pulse 1.5s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

// Make sure this is included if not already present
@media (max-width: 768px) {
  .skeletonCharacterGrid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .skeletonCharacterGrid {
    grid-template-columns: 1fr;
  }
}

.loadMoreButton {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: $pink-2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($pink-2, 10%);
  }
}

.endMessage {
  text-align: center;
  margin-top: 20px;
  color: #888;
}

.error {
  text-align: center;
  color: #ff6b6b;
  margin-top: 20px;
}

.skeletonCharacterGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.skeletonCharacterCard {
  background-color: #1e1e1e7c;
  border-radius: 10px;
  overflow: hidden;
  height: 400px;

  .skeletonImageContainer {
    height: 300px;
    background-color: $black-bg;
    animation: pulse 1.5s infinite;
  }

  .skeletonContent {
    padding: 10px;

    .skeletonTitle {
      height: 20px;
      background-color: #2a2a2a;
      margin-bottom: 10px;
      animation: pulse 1.5s infinite;
    }

    .skeletonDescription {
      height: 40px;
      background-color: #2a2a2a;
      animation: pulse 1.5s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 400px;
  border-radius: 8px;
  padding: 20px;
  text-align: center;

  h2 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #888888;
    margin-bottom: 20px;
  }
}

.exploreButton {
  background-color: $pink-2;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $pink-50;
  }
}

.container {
  padding: 20px;
  position: relative;
  max-width: 100%;
}

.filterRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.dropbtn::after {
  content: '▼';
  font-size: 12px;
  margin-left: 10px;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #1e1e1e;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}

.dropdownContent a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdownContent a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdownContent a.active {
  background-color: #8e44ad;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.discoverNew {
  color: #ff69b4;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-left: 10px;

  &:hover {
    transform: scale(1.05);
  }
}

.characterGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.characterCard {
  background-color: $darker-bg;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }
  
  .imageContainer {
    position: relative;
    
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
    
    .likes {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 14px;
    }

    .nsfwTag {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #ff4757;
      color: #fff;
      padding: 3px 8px;
      border-radius: 10px;
      font-size: 12px;
    }

    .deluxeTag {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: gold;
      color: black;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 14px;
    }
  }
  
  h3 {
    padding: 10px 10px 5px;
    font-size: 18px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  p {
    padding: 0 10px 10px;
    font-size: 14px;
    color: #aaa;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .cardFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    
    .messages {
      color: #aaa;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .characterGrid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

}

@media (max-width: 480px) {
  .characterGrid {
    grid-template-columns: 1fr;
  }
}