@import "../vars";
@import url('https://fonts.googleapis.com/css2?family=Proto+Mono:wght@400;700&display=swap');

[data-state="active"] {
  background-color: white;
}

.hoverScaleButton {
  background-color: $pink-25;
  border: 2px solid $pink-50;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  transition: 0.2s ease-in-out;
  max-width: 250px;

  &:hover {
    transform: scale(1.025);
    background-color: $pink-50;
  }
}

.scrollIndicator {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
}

.videoContainer {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  overflow: hidden;

  h2 {
    color: #fff;
    margin-bottom: 3px;
    font-weight: bold;
    font-size: 18px;
    
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}

.video {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
  border: 2px dashed $pink-50;
}

.scrollControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }

  button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  span {
    font-size: 14px;
    color: #fff;
    
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.blinker {
  height: 15px;
  width: 1px;
  background: #fff;
  animation: blinker .75s ease infinite;
  display: inline-block;

  @keyframes blinker {
    0%, 100% { opacity: 0; }
    25%, 50% { opacity: 1; }
  }
}

.text {
  flex: 1;
  font-family: 'Proto Mono', Helvetica, Arial, sans-serif;
  color: #fff;
  max-width: 750px;
  font-size: 16px;
  z-index: 99999 !important;
  margin: 10px 0;
  font-weight: bold;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 8px 0;
  }

  svg {
    margin-right: 10px;
    path {
      fill-opacity: 1;
    }
  }
}

.container {
  width: 100%;
  height: auto;
  min-height: 75vh;
  padding: 20px;
  margin: 0;
  background-color: transparent;
  backdrop-filter: blur(0px);
  border-radius: 15px;
  
  @media (max-width: 768px) {
    padding: 10px;
  }
}

.crt {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.circle {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $darker-bg;
  opacity: 0;
  transition: opacity 0.22s ease-in-out;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin: 0;
}

@media (max-width: 1000px) {
  .topRow {
    grid-template-columns: 1fr;
  }

  .bottomRow {
    flex-direction: column;
  }
}