@import "../../assets/vars.scss";

.securityInputWrapper {
  display: flex;
  gap: 10px;

  input {
    text-align: center;
    background-color: #f9f9f9;
    color: #313131;
    outline: none;
    border: 2px solid $pink;
    border-radius: 5px;
    height: 50px;
    padding: 0 15px;
    font-size: 30px;
    font-weight: bold;
    caret-color: #fff;
    transition: border 100ms ease, box-shadow 100ms ease, background-color 100ms ease;
    width: 100%;

    &:hover {
      border: 2px solid $pink-2;
    }

    &:focus {
      border: 2px solid #fff;
      box-shadow: 0 0 20px 1px $pink-25;
      background-color: $pink-10;
    }
  }

  &.error {
    input {
      border: 2px solid $red;
      box-shadow: 0 0 20px 1px $red-25;
      background-color: $red-10;
      color: $red;
    }
  }
}

.errorText {
  color: $red;
  font-size: 10px;
  margin-top: 10px;
}
