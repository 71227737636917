@import "../vars";

.personasContainer {
  padding: 20px;
  height: 75vh;
  
  h2 {
    margin-bottom: 20px;
    color: $pink;
  }
}

.personasList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.createPersona, .personaCard {
  background-color: $pink-25;
  border: 2px dashed $pink-50;
  border-radius: 8px;
  color: #fff;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $pink-50;
  }
}

.createPersona {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  
  span {
    font-size: 24px;
    margin-bottom: 1px;
  }
}

.personaCard {
  h3 {
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: #fff;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.686);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #000;
  border: 2px dashed #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;

  h2 {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    input, textarea, select {
      padding: 10px;
      border: 1px solid $pink-10;
      border-radius: 4px;
      background-color: $pink-25;
      color: white;

      &::placeholder {
        color: #ffffff54;
      }
    }

    textarea {
      height: 100px;
      resize: vertical;
    }
  }
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:first-child {
      background-color: $pink-10;
      color: white;

      &:hover {
        background-color: darken($pink-10, 10%);
      }
    }

    &:last-child {
      background-color: $pink;
      color: white;

      &:hover {
        background-color: darken($pink, 10%);
      }
    }
  }
}

@media (max-width: 768px) {
  .personasList {
    grid-template-columns: 1fr;
  }

  .modalContent {
    width: 95%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 12px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $pink-50;
}

input:checked + .slider:before {
  transform: translateX(26px);
}