@import "../../assets/vars.scss";

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #000;
  border: 2px solid $pink-50;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
}

.modalTitle {
  color: $pink-2;
  font-size: 24px;
  margin-bottom: 15px;
  text-shadow: 0 0 25px $pink-2;
}

.modalText {
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 100ms ease;
}

.cancelButton {
  background-color: $pink-5;
  color: #fff;
  border: 1px solid $pink-25;

  &:hover {
    background-color: $pink-10;
  }
}

.confirmButton {
  background-color: $pink;
  color: #000;

  &:hover {
    background-color: darken($pink, 10%);
  }
}
