@import "../vars";

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.blurred {
  filter: blur(10px);
  transition: filter 0.3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.content {
  text-align: center;
}

.ratingText {
  font-size: 14px;
  margin-bottom: 10px;
}

.showButton {
  background: $pink-2;
  border: 2px solid $pink-50;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  border: none;
  padding: 5px 22px;
  border-radius: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: .25s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}


@keyframes skeleton-loading {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.skeletonImage {
  width: 100%;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.1);
}

.skeletonText {
  height: 1em;
  margin-bottom: 0.5em;
  background-color: rgba(255, 255, 255, 0.1);
}

.skeletonTitle {
  width: 70%;
  height: 1.5em;
}

.skeletonDescription {
  width: 90%;
}

.skeletonTag, .skeletonMessages {
  width: 30%;
  display: inline-block;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 400px;
  border-radius: 8px;
  padding: 20px;
  text-align: center;

  h2 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #888888;
    margin-bottom: 20px;
  }
}

.exploreButton {
  background-color: $pink-2;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $pink-50;
  }
}

.container {
  padding: 20px;
}

.filterRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.dropbtn::after {
  content: '▼';
  font-size: 12px;
  margin-left: 10px;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #1e1e1e;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}

.dropdownContent a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdownContent a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdownContent a.active {
  background-color: #8e44ad;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.discoverNew {
  color: #ff69b4;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  margin-left: 10px;

  &:hover {
    transform: scale(1.05);
  }
}

.characterGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.characterCard {
  background-color: $darker-bg;
  border-radius: 10px;
  overflow: hidden;
  transition: .25s ease-in-out;
  
  .imageContainer {
    position: relative;
    
    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }
    
    .likes {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 14px;
    }

    .deluxeTag {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: gold;
      color: black;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 14px;
    }
  }
  
  h3 {
    padding: 10px;
    font-size: 18px;
    color: #fff;
  }
  
  p {
    padding: 0 10px 10px;
    font-size: 14px;
    color: #ccc;
  }
  
  .cardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    
    .nsfwTag {
      background-color: $pink;
      color: #fff;
      padding: 3px 8px;
      border-radius: 10px;
      font-size: 12px;
    }
    
    .messages {
      color: #ccc;
      font-size: 14px;
    }
  }
}

.characterCard:hover {
  transition: .1s ease-in-out;
  transform: scale(1.02);
  cursor: pointer;
}

@media (max-width: 768px) {
  .characterGrid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .characterGrid {
    grid-template-columns: 1fr;
  }
}

// Add these styles to your existing Favorites.module.scss file

.threeDotMenu {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.menuButton {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.menuContent {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #2a2a2a;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    background: none;
    border: none;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}