.resetPage {
  min-height: 75vh;
  display: flex;
  justify-content: center;
  //align-items: center;
  //overflow: auto;
  padding-top: 144px;
}

.container {
  width: 100%;
  max-width: 440px;
  padding: 0 20px;
}

.elementWrapper {
  margin-bottom: 20px;
}

.subtitle {
  color: #fff;
  font-weight: bold;
  text-shadow: 0 0 15px rgba(195,221,255,0.25);
  margin-bottom: 30px;
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .elementWrapper {
    margin-bottom: 0;
  }
}

.centered {
  text-align: center;
  color: #dc8deec5
}
